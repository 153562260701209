












































import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { IDocSectionsMeta } from "@monorepo/catalog/src/views/DocTypeView/types/docTypeElement";
import { elementStatuses } from "@monorepo/utils/src/variables/projectsData/versionCatalogView/elementStatuses";
import moment from "moment";

export default defineComponent({
  name: "MetaInfo",
  data() {
    return {
      isLoadingCopy: false,
      isLoadingActivate: false,
    };
  },
  computed: {
    ...mapGetters("docTypeView", ["meta"]),
    metaInfoItems() {
      const versionObj = (this.meta as unknown as IDocSectionsMeta | null)?.version;
      const statusObj = elementStatuses.find((status) => status.value === versionObj?.state);
      return [
        {
          title: "Версия",
          value: versionObj?.version || "",
        },
        {
          title: "Источник",
          value: versionObj?.parentVersion?.version || "",
        },
        {
          title: "Статус",
          value: statusObj?.title || "",
        },
        {
          title: "Дата создания",
          value: versionObj?.createAt ? moment(versionObj.createAt).format("YYYY-MM-DD") : "",
        },
        {
          title: "Комментарий",
          value: versionObj?.comment || "",
          type: "input",
        },
      ];
    },
    isEditMode() {
      return this.$route.query.mode === "edit";
    },
    isDraftVersion() {
      return (this.meta as IDocSectionsMeta)?.version?.state === "DRAFT";
    },
  },
  methods: {
    ...mapActions("docTypeView", ["changeVersion", "addNewVersion", "refreshScroll", "activateVersion"]),
    ...mapMutations("docTypeView", ["addMeta"]),
    async copyVersion() {
      if (this.meta?.version?.id) {
        this.isLoadingCopy = true;
        const newData = await this.addNewVersion(this.meta.version.id);
        if (newData?.id) {
          this.$router
            .replace({ query: { ...(this.$route.query || {}), type: this.meta?.version?.type, versionId: newData.id, mode: "edit" } })
            .catch(console.warn);
          this.addMeta(null);
          this.refreshScroll();
        }
        this.isLoadingCopy = false;
      }
    },
    async activateVersionCb() {
      if (this.meta?.version?.id) {
        this.isLoadingActivate = true;
        const isActivated = await this.activateVersion(this.meta.version.id);
        if (isActivated) {
          this.addMeta(null);
          this.refreshScroll();
        }
        this.isLoadingActivate = false;
      }
    },
    onChangeComment(comment: string) {
      this.changeVersion({
        ...((this.meta as unknown as IDocSectionsMeta | null)?.version || {}),
        comment,
      });
    },
  },
});
