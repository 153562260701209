

























































































































import { Route } from "vue-router";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "@monorepo/uikit/src/components/common/Table.vue";
import AddElement from "@monorepo/uikit/src/components/tableViews/AddElement.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/uikit/src/components/common/Filters.vue";
import DocTypeInfoModal from "@monorepo/catalog/src/views/DocTypeView/components/DocTypeInfoModal.vue";
import Footer from "@monorepo/uikit/src/components/common/Footer.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/docTypeView/tableHeaders";
import { defineComponent, ref } from "@vue/composition-api";
import { module as DocTypeView } from "@monorepo/catalog/src/store/modules/docTypeView";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { mapActions, mapGetters, mapMutations, Module } from "vuex";
import { breadcrumbs } from "@monorepo/catalog/src/views/DocTypeView/constants/breadcrumbs";
import MetaInfo from "@monorepo/catalog/src/views/DocTypeView/components/MetaInfo.vue";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ToggleTableCellsContainer from "./components/ToggleTableCellsContainer.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/docTypeView/viewTitle";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import { IDocSection, IDocSectionsMeta, IDocTypeElement } from "@monorepo/catalog/src/views/DocTypeView/types/docTypeElement";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import ToggleDocumentView from "@monorepo/uikit/src/components/common/ToggleDocumentView.vue";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import useGetTableLibs from "@monorepo/catalog/src/views/DocTypeView/composables/useGetTableLibs";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";
import ExportFooterButton from "@monorepo/uikit/src/components/tableViews/ExportFooterButton.vue";
import ConcatFooterButton from "@monorepo/uikit/src/components/tableViews/ConcatFooterButton.vue";
import RestoreFooterButton from "@monorepo/uikit/src/components/tableViews/RestoreFooterButton.vue";
import DeleteFooterButton from "@monorepo/uikit/src/components/tableViews/DeleteFooterButton.vue";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { convertApiItemToUi } from "@monorepo/catalog/src/views/DocTypeView/utils/convertApiItemToUi";
import useMoveByAuthorities from "@monorepo/utils/src/composables/useMoveByAuthorities";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/docTypeView/filtersElements";
import { currentFilters as setCurrentFilters } from "@monorepo/catalog/src/views/DocTypeView/utils/defaultCurrentFilters";
import useFooterActions from "@monorepo/utils/src/composables/useFooterActions";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";

const SECTION_NAME = "docTypeView";
const pluralFormTitles = ["Вид документа", "Вида документа", "Видов документа"];

export default defineComponent({
  name: "DocTypeView",
  components: {
    TableSettings,
    ExportButton,
    ToggleTableCellsContainer,
    Table,
    AddElement,
    Filters,
    InfiniteScroll,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
    SortPanelContainer,
    DocTypeInfoModal,
    Footer,
    ToggleDocumentView,
    ExportFooterButton,
    ConcatFooterButton,
    RestoreFooterButton,
    DeleteFooterButton,
    MetaInfo,
    DocumentTree: () =>
      import(
        /* webpackChunkName: "DocTypeViewTree" */
        "@monorepo/catalog/src/views/DocTypeView/components/DocumentTree.vue"
      ),
    DocumentTreeRead: () =>
      import(
        /* webpackChunkName: "DocTypeViewTreeRead" */
        "@monorepo/catalog/src/views/DocTypeView/components/DocumentTreeRead.vue"
      ),
  },
  data() {
    return {
      breadcrumbs,
      ModalType,
      viewTitle,
      viewUniqKey,
      isOnceSelected: false,
      educationIds,
      pluralFormTitles,
      tableHeaders,
      convertApiItemToUi,
      filtersElements,
      setCurrentFilters,
      ViewMode,
      autocompleteFiltersKeys: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters(SECTION_NAME, [
      "isOpenFilters",
      "cells",
      "isSelectAll",
      "infiniteId",
      "data",
      "totalLength",
      "selectedIds",
      "isTableLoading",
      "sectionsList",
      "meta",
      "elasticValue",
      "storageTerms",
      "viewMode",
    ]),
    currentDocumentView(): ViewMode {
      return this.viewMode?.typeDisplay || ViewMode.TABLE;
    },
    resultFiltersElement(): any {
      return !this.elasticValue ? this.filtersElements.slice(1) : this.filtersElements;
    },
    isDraftVersion() {
      return (this.meta as IDocSectionsMeta)?.version?.state === "DRAFT";
    },
    isReadMode(): boolean {
      return this.$route.query.mode === "read";
    },
    isShowConcatBtn(this: { isSelectAll: boolean; selectedNotDeletedLength: number; user: { authorities: { authority: authorities }[] } }): boolean {
      const isAuthority = [authorities.ARCHIVE_CONCAT, authorities.ARCHIVE_MODIFY].every((authority) => isAuthorityExist(this.user, authority));
      return !this.isSelectAll && this.selectedNotDeletedLength > 1 && isAuthority;
    },
    isCanDelete(): boolean {
      return isAuthorityExist(this.user, authorities.DOC_KIND_DELETE);
    },
    isCanConcat(): boolean {
      return isAuthorityExist(this.user, authorities.DOC_KIND_MODIFY);
    },
    isCanCreate(): boolean {
      return isAuthorityExist(this.user, authorities.DOC_KIND_CREATE);
    },
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.DOC_KIND_MODIFY);
    },
    isShowFooter(): boolean {
      return (this.isSelectAllElement || !!Object.values(this.selectedIds).filter(Boolean).length) && (this.isShowDeleteBtn || this.isShowRestoreBtn);
    },
    headers(): typeof tableHeaders {
      return tableHeaders;
    },
    sectionsTreeItems() {
      return ((this.sectionsList as IDocSection[]) || []).map((section) => ({
        ...section,
        name: `${section.orderIndexPath} ${section.name}`,
        ...(section.subsections
          ? {
              children: section.subsections
                .sort((a, b) => a.orderIndex - b.orderIndex)
                .map((subsection) => ({ ...subsection, name: `${subsection.orderIndexPath} ${subsection.name}` })),
            }
          : {}),
      }));
    },
    sectionsLib() {
      return (this.sectionsList as IDocSection[]).map((section) => ({
        value: section.id,
        title: section.name || "",
      }));
    },
    storageTermsValues() {
      return (this.storageTerms as Record<string, string>[]).map((storage) => ({
        value: storage.id,
        title: storage.value || "",
      }));
    },
    selectItemsObj(): Record<string, unknown> {
      return {
        sectionsLib: this.sectionsLib,
        sectionsTreeItems: this.sectionsTreeItems,
        storageTerms: this.storageTermsValues,
      };
    },
    params() {
      return {
        type: this.$route.query.type,
        versionId: this.$route.query.versionId,
      };
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch(`${SECTION_NAME}/setIsSelectAll`, value);
      },
    },
  },
  methods: {
    ...mapMutations(SECTION_NAME, ["refreshData", "setSelectedIds", "clearFilters"]),
    ...mapActions(SECTION_NAME, [
      "getEventList",
      "addOpenedId",
      "getExportData",
      "setIsOpenFilters",
      "refreshScroll",
      "deleteElement",
      "restoreEntity",
    ]),
    openModalIfNeed(this: {
      isOnceSelected: boolean;
      $route: Route;
      data: IDocTypeElement[];
      addOpenedId: (id: string) => void;
      showModal: boolean;
    }) {
      if (this.isOnceSelected) {
        return;
      }

      this.isOnceSelected = true;

      if (this.$route.query?.isOpenModal && this.data.length) {
        if (this.$route.query?.id) {
          this.addOpenedId(this.$route.query?.id as string);
          this.showModal = true;
          return;
        }
        this.addOpenedId(this.data[0].id);
        this.showModal = true;
      } else if (this.$route.query?.card && this.data.length) {
        this.addOpenedId(this.$route.query?.card as string);
        this.showModal = true;
      }
    },
    openConcatModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ConcatArchiveModal"));
    },
    openModal({ header, event }: { header: { value: string; isLink: boolean }; event: Record<string, unknown> }) {
      switch (header.value) {
        case "openModal":
          if (event.id) {
            this.addOpenedId(event.id);
            this.openCardModal(event.isDeleted as boolean);
          }
          break;
        default:
          break;
      }
    },
    openCardModal(this: { toggleModal: (value: boolean, type?: ModalType) => void; isCanEdit: boolean }, isDeleted: boolean) {
      if (isDeleted) {
        return this.toggleModal(true, ModalType.READ);
      }
      this.toggleModal(true, this.isCanEdit ? ModalType.EDIT : ModalType.READ); // NOT SIMPLIFIED!
    },
    toggleModal(value: boolean, type?: ModalType) {
      this.showModal = value;
      this.type = type || ModalType.NEW;
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
  },
  setup(props, { root }) {
    const sectionName = ref(SECTION_NAME); // sectionName === store module name
    const filtersLoaded = ref(false);
    const showModal = ref(false);
    const type = ref(ModalType.EDIT);
    const moveByAuthorities = useMoveByAuthorities(root);

    const openModal = () => {
      if (root.$store.getters[`${SECTION_NAME}/openedId`]) {
        showModal.value = true;
        const isCanEdit = !!root.$store.getters["auth/user"]?.authorities?.find((element: { authority: string }) => {
          return element.authority === authorities.ARCHIVE_MODIFY;
        });
        type.value = isCanEdit ? ModalType.EDIT : ModalType.READ;
      }
    };

    const { isShowDeleteBtn, isShowRestoreBtn, openDeleteModal, openRestoreModal } = useFooterActions(
      root,
      SECTION_NAME,
      pluralFormTitles,
      authorities.DOC_KIND_DELETE
    );

    useInitTableStoreModule(root, SECTION_NAME, DocTypeView as Module<unknown, unknown>);
    useOpenHistoryMode(root, SECTION_NAME, openModal);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders);
    useSetStartFilters(root, SECTION_NAME, ["id"], []).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
      useSetStartModal(root, SECTION_NAME);
      openModal();
    });
    useGetTableLibs(root);
    return {
      filtersLoaded,
      showModal,
      type,
      sectionName,
      moveByAuthorities,
      isShowDeleteBtn,
      isShowRestoreBtn,
      openDeleteModal,
      openRestoreModal,
    };
  },
});
