import { DictionaryStatuses } from "@monorepo/utils/src/variables/dictionaryStatuses";
import { IDocChapter, IDocSection } from "@monorepo/catalog/src/views/DocTypeView/types/docTypeElement";

export const convertStatus = (archiveIsDeleted: boolean): string => (!archiveIsDeleted ? DictionaryStatuses.ACTIVE : DictionaryStatuses.DELETED);

export const convertApiItemToUi = (item: any): any => {
  return {
    ...item,
    storageTermValue: item.storageTermValue ? { id: item.storageTermValue.id, label: item.storageTermValue.value || "" } : null,
    sectionName: getResultSectionName(item.section) || "",
    highlights: item.highlightFields,
  };
};

export const getAddBtnObj = (section?: any) => {
  return {
    isAddBtn: true,
    parentId: section?.id || null,
    name: "",
    parent: section,
    ...(!section
      ? { isAddSectionBtn: true }
      : (section.documentKinds as any[])?.length
      ? { isAddDocumentBtn: true }
      : (section.subsections as any[])?.length
      ? { isAddSectionBtn: true }
      : { isAddDocumentBtn: true, isAddSectionBtn: true }),
  };
};

export const convertApiTreeItemToUi = (section: IDocSection | IDocChapter, isConcatButtonItem: boolean, isConcatOrderPath?: boolean): any => {
  return {
    ...section,
    name: isConcatOrderPath ? `${section.orderIndexPath || ""} ${section.name || ""}` : section.name,
    children: (((section.documentKinds as any[])?.length ? section.documentKinds : section.subsections) || [])
      .sort((a, b) => a.orderIndex - b.orderIndex)
      .map((item) => convertApiTreeItemToUi(item, isConcatButtonItem, isConcatOrderPath))
      .map((item) => ({ ...item, parentId: section.id }))
      .concat((section.documentKinds || section.subsections) && isConcatButtonItem ? getAddBtnObj(section) : []),
    ...(section.storageTermValue ? { storageTermValue: section.storageTermValue?.id } : {}),
    ...(section.storageTermList
      ? { storageTermList: section.storageTermList.map((storageItem) => ({ ...storageItem, storageTermValue: storageItem.storageTermValue?.id })) }
      : {}),
  };
};

export const getResultSectionName = (item: any) => {
  const orderIndexPath = item?.orderIndexPath ? `${item.orderIndexPath} ` : "";
  let result = `${orderIndexPath}${item?.name || ""}`;
  if (item?.parent?.name) {
    result = `${getResultSectionName(item?.parent)} ${result || ""}`;
  }
  return result || "";
};

export const getResultSectionIndex = (item: any) => {
  let result = item.orderIndex || 0;
  if (item.subsections?.length) {
    result = Math.max(...item.subsections.map(getResultSectionIndex));
  }
  return result;
};
